import './partner-list.scss';
import { Slider } from '../slider/slider';
import 'swiper/scss';

class PartnerList {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-partner-list',
            items: 'data-partner-list="slides"',
            onInit: null,
            offset: 50,
            prevnext: true,
            breakpoints: null,
            slidesPerView: 'auto',
            slidesPerGroup: 1
        };

        this.settings = Object.assign({}, defaults, options);
        this.$partnerList = element;
        this.slider = '';
        this.partnerListResizer = '';
    }

    initialize () {
        this.initSlider(this.$partnerList);
    }

    checkNavigationButtons ($slides) {
        if ($slides.querySelector('[data-partner-list="prev"]').classList.contains('swiper-button-disabled') && $slides.querySelector('[data-partner-list="next"]').classList.contains('swiper-button-disabled')) {
            $slides.classList.add('slider--nav-hide');
        } else {
            $slides.classList.remove('slider--nav-hide');
        }
    }

    initSlider (slider) {
        this.slider = new Slider(slider, {
            initAttr: this.settings.initAttr,
            autoplay: false,
            breakpoints: this.settings.breakpoints,
            crossfade: false,
            calculateHeight: false,
            slidesPerView: this.settings.slidesPerView,
            slidesPerGroup: this.settings.slidesPerGroup,
            // parallax: true,
            prevnext: this.settings.prevnext,
            speed: 750,
            effect: 'slide',
            onInit: () => {
                this.partnerListResizer = window.setTimeout(() => {
                    this.checkNavigationButtons(this.$partnerList.querySelector('[' + this.settings.items + ']'));
                    this.slider.update();
                }, 500);
            },
            onResize: () => {
                clearTimeout(this.partnerListResizer);
                this.partnerListResizer = window.setTimeout(() => {
                    this.checkNavigationButtons(this.$partnerList.querySelector('[' + this.settings.items + ']'));
                    this.slider.update();
                }, 500);
            }
        });
    }
}

export { PartnerList };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$partnerList = $context.querySelectorAll('[data-partner-list="root"]');
        for (let i = 0; i < $$partnerList.length; i++) {
            const $partnerList = new PartnerList($$partnerList[i]);
            $partnerList.initialize();
        }
    }
});
